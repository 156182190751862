import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import * as XLSX from "xlsx";
import ReactPaginate from "react-paginate";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import admindata from "../../components/admindata";
import "./invoice.css";
import html2pdf from "html2pdf.js";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const DepositReport = () => {
  const getOnUsersSiteSetting = useSelector(
    (state) => state.home.getOnUsersSiteSetting
  );
  const invoiceRef = useRef();
  const location = useLocation();
  const userData = location.state?.userData;

  const [CompanyName, setCompanyName] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");

  const [user, setUser] = useState();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    if (getOnUsersSiteSetting) {
      setCompanyName(getOnUsersSiteSetting.CompanyName);
      setCompanyMobile(getOnUsersSiteSetting.CompanyMobile);
      setCompanyAddress(getOnUsersSiteSetting.CompanyAddress);
    }
  }, [getOnUsersSiteSetting]);

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days"),
  ]);

  const profle = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `txn/depositreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.datefind);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return newDate;
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, values]);

  if (user === undefined) {
    return null;
  }

  const Print = () => {
    let printContents = document.getElementById("printablediv");
    let originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents.innerHTML;

      window.print();

      document.body.innerHTML = originalContents;
    } else {
      console.error("Element with id 'printablediv' not found.");
    }
  };

  const createDownloadData = () => {
    handleExport();
  };
  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Deposit Amount",
        E: "Deposit Status",
      },
    ];

    userData?.data?.User_id?.forEach((row) => {
      const userDetails = row;
      table1.push({
        A: userDetails._id,
        B: userDetails.User_id ? userDetails.User_id.Name : "",
        C: userDetails.User_id ? userDetails.User_id.Phone : "",
        D: userDetails.amount ? userDetails.amount : "",
        E: userDetails.status ? userDetails.status : "",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "DepositReport.xlsx");
  };

  const generatePDF = () => {
    const element = invoiceRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 0.1,
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
      })
      .save();
  };

  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body text-light">
              <h4 className="card-title">Deposit Reports</h4>
              <button
                onClick={() => {
                  createDownloadData();
                }}
                className="btn btn-primary"
              >
                Export Data
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={generatePDF}
              >
                Print
              </button>
              <div>
                <select
                  className="form-control col-sm-1 m-1 bg-dark text-light"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              <DatePicker value={values} onChange={setValues} range />
              <div style={{ padding: 20 }}></div>
              <div className="table-responsive">
                <div ref={invoiceRef}>
                  <div
                    className=""
                    style={{ background: "#fff", color: "#000" }}
                  >
                    <div className="invoice-border">
                      <div className="invoice-flex">
                        <div className="invoice-img">
                          <img src="/mjludologo.png" alt="logo" />
                        </div>
                        <div className="invoice-h1">
                          <h1>Tax Invoice</h1>
                        </div>
                      </div>
                      <h2 className="invoice-h2">
                        SYNTHLOOM SOLUTIONS PRIVATE LIMITED
                      </h2>
                      <h3 className="invoice-h3">GSTN: 08ABMCS4675F1ZV</h3>
                      <h4 className="invoice-h4">
                        Address: Plot No. F-4, Nandpuri Colony,sodala, Shyam
                        Nagar (jaipur), Jaipur, Jaipur, Rajasthan, India, 302019
                      </h4>
                    </div>
                    <div className="invoice-border side ">
                      <h4 className="invoice-h4">
                        Reference No: {userData?.data?.referenceId}
                      </h4>
                      <h4 className="invoice-h4">
                        Ip: {userData?.data?.client_ip}
                      </h4>
                      {/* <h4 className="invoice-h4">User Name: Rashid</h4> */}
                      {/* <h4 className="invoice-h4">User Id: ****779572</h4> */}
                      {/* <h4 className="invoice-h4">Place of Supply: Jaipur</h4> */}
                      <h4 className="invoice-h4">
                        Invoice Date :{" "}
                        {dateFormat(userData.data?.createdAt).split(",")[0]}{" "}
                      </h4>
                    </div>
                    <div className="invoice-border padding invoice-h1">
                      <h1 className="">
                        User Id: {userData?.data?.User_id?._id}
                      </h1>
                    </div>
                    <div className="invoice-border ">
                      <table className="invoice-table color">
                        <tr>
                          <th>Description</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <td>Deposit Amount (excl. Govt. Tax)</td>
                          <td>{userData?.data?.taxableAmount}</td>
                        </tr>
                        <tr>
                          <td>Govt. Tax. (28% GST)</td>
                          <td>{userData?.data?.gst}</td>
                        </tr>
                        <tr>
                          <td>Total Payable Amount</td>
                          <td>{userData?.data?.amount}</td>
                        </tr>
                      </table>
                    </div>

                    <div className="invoice-border">
                      <table className="invoice-table">
                        <tr>
                          <th>Total Taxable Amount</th>
                          <th>{userData?.data?.taxableAmount}</th>
                        </tr>
                        <tr>
                          <td>CGST (14%)</td>
                          <td>
                            {(
                              (userData?.data?.taxableAmount * 14) /
                              100
                            )?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>SGST (14%)</td>
                          <td>
                            {(
                              (userData?.data?.taxableAmount * 14) /
                              100
                            )?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>IGST (28%)</td>
                          <td>0</td>
                        </tr>
                      </table>
                    </div>
                    <div className="invoice-border">
                      <table className="invoice-table">
                        <tr>
                          <th>Total</th>
                          <th>{userData?.data?.amount}</th>
                        </tr>
                      </table>
                      <p className="invoice-message">
                        Taxable Amt + CGST + SGST +IGST
                      </p>
                      {/* <h4 className="invoice-words">
                        Amount in Words: Ten Rupees Only
                      </h4> */}
                    </div>
                    <div className="invoice-border pt-5 pb-5">
                      <h4 className="text-center">
                        This is computer generated Invoice, hence signature is
                        not required.
                      </h4>
                    </div>
                    {/* <div className="invoice-qr">
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100px",
                          width: "100%",
                        }}
                        value={
                          <InvoiceQrCode
                            pageprint={pageprint}
                            printRef={printRef}
                            invoice_data={invoice_data}
                          />
                        }
                        viewBox={`0 0 256 256`}
                      />
                      <h4>
                        This is computer generated Invoice, hence signature is
                        not required.
                      </h4>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositReport;
